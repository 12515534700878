<template>
    <div>
        <a @click="viewDetails" style="cursor: pointer; color: blue">{{ data.id }}</a>
        <b-modal
            v-model="modalShow"
            size="xl"
            scrollable
            cancel-disabled
            :title="modalTitle"
        >
            <div class="row">
                <div class="col-12" style="overflow-x: scroll">
                    <b-table bordered striped :items="data.details" :fields="fields">

                    </b-table>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <b-button size="sm" variant="primary" @click="modalShow = false">
                    OK
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';
import { TabPlugin } from '@syncfusion/ej2-vue-navigations';

Vue.use(TabPlugin);
export default {
    name: "columnTemplate",
    data: function() {
        return {
            data: {},
            fields:[
                { label: "Time", key: "time" },
                { label: "status", key: "status" },
                { label: "URL", key:"url" },
                { label: "Headers", key:"headers" },
                { label: "Request Headers", key:"request_headers" },
                { label: "Protocol", key: "data_protocol" },
                { label: "Host/IP", key: "data_ip" },
                { label: "Port", key: "data_port" }
            ],
            modalTitle:"Modal Title",
            modalShow:false,
        }
    },
    methods:{
        viewDetails: function(){
            this.modalTitle = `Report Details (${this.data.provider}) ${this.data.date}`;
            this.modalShow = true;
        }
    }
}
</script>

<style scoped>

</style>
