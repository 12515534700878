<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Proxy Error Logs - Counts</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="time" v-model="filter.start" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="time" v-model="filter.end" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <select class="form-control" v-model="filter.period">
                                <option selected :value="undefined">--Select Period--</option>
                                <option v-for="item in period" :key="item" :value="item">{{item}}</option>
                            </select>
                        </div>

                        <div class="form-group mr-1">
                            <select class="form-control" v-model="filter.provider">
                                <option selected value="">--Any Provider--</option>
                                <option v-for="item in providers" :key="item" :value="item">{{item}}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select class="form-control" v-model="filter.product">
                                <option selected value="">--Any Product--</option>
                                <option v-for="item in products" :key="item" :value="item.identifier">{{item.name}}</option>
                            </select>
                        </div>
                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="search"
                            style="background-color: #383838"
                        >
                            <i class="fa fa-search" title="Search"></i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mr-1"
                            v-if="showExportButton"
                            @click="exportToExcel"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <ejs-grid

                        ref="grid"
                        id="Grid"
                        :dataSource="reports"
                        :allowExcelExport='true'
                        :allowPaging="true"
                        :allowSorting="true"
                        :allowGrouping='true'
                        :allowFiltering="true"
                        :allowResizing='true'
                        :filterSettings="filterSettings"
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column field='date' minWidth='8' width='100' headerText="Date" format='yMd' type='date'></e-column>
                            <e-column field='time_frame' minWidth='8' width='85' headerText="Time"></e-column>
                            <e-column field='provider' minWidth='8' width='110' headerText='Provider'></e-column>
                            <e-column field='message' headerText='Message' minWidth='8' width='800'></e-column>
                            <e-column field='count' headerText='Error Count' minWidth='8' width='100'></e-column>
                            <e-column field='attempts' headerText='Attempts'></e-column>
                            <e-column field='percentage' headerText='Percentage'></e-column>
                            <e-column field='product_type' minWidth='8' width='110' headerText='Product Type'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert";
import Vue from "vue";
import moment from "moment";
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Filter,
    Resize
} from "@syncfusion/ej2-vue-grids";
import pTemplate from "@/components/pTemplate";
import sha1 from "js-sha1";


Vue.use(GridPlugin);

export default {
    name: 'proxy.provider.error',
    props:['user'],
    components:{

    },
    data: function () {
        return {
            period:['Daily', 'Hourly'],
            filter:{
                to: '',
                from: '',
                provider:"",
                type: "fail",
                time:'',
                client: '',
                product: '',
                start: "00:00",
                end: "23:59"
            },
            dropdownClients: [],
            clients: [],
            products: [],
            report:[],
            reports:[],
            providers:[

            ],
            statuses: [
                'good',
                'bad'
            ],
            timeSelector:['12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'],
            proxyDataset: {},
            pageSettings: {pageSize: 100},
            groupSettings:  {
                showDropArea: false,
                disablePageWiseAggregates: true,
                columns: ['date', 'provider','time_frame','product_type']
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            searching: false,
            showExportButton: false,
            reportLoading: false,
            pTemplate: function () {
                return {
                    template : pTemplate
                }
            }
        }
    },
    created: function(){
        this.load();
        this.loadProviders();
        this.loadProducts();
        this.GetClientsList();
        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Filter,
            Resize
        ]
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        actionHandler: function(args) {
            console.log(args);
        },
        load: function () {
            //Load the campaign
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            const dateString = [year, month, day].join('-');
            this.filter.from = dateString;
            this.filter.to = dateString;
        },
        loadProviders: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/proxies`, {params:request}).then(function(resp){
                //Store the stats
                this.providers = (resp.data && resp.data.data)? resp.data.data : [];

            }.bind(this));
        },
        loadProducts: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.products = (resp.data && resp.data.data)? resp.data.data : [];
                this.products = this.products.filter(item => item.identifier === 'domain' || item.identifier === 'email');


                //this.$root.preloader = false;
            }.bind(this));
        },
        GetClientsList: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        search: function(){
            this.searching = true;
            this.proxyDataset = [];
            this.report = [];
            this.reportLoading = true;

            var request = {'apikey': this.$root.apikey,  filters:{}};

            if(this.filter.from) request.filters.from = this.filter.from;
            if(this.filter.to) request.filters.to = this.filter.to;
            if(this.filter.provider) request.filters.provider = this.filter.provider;
            if(this.filter.start) request.filters.start = this.filter.start;
            if(this.filter.end) request.filters.end = this.filter.end;
            if(this.filter.period) request.filters.period = this.filter.period;
            if(this.filter.product) request.filters.product = this.filter.product;

            axios.get(`${this.$root.serverUrl}/admin/reports/proxy/error-logs/count`,{params:request}).then((resp) => {
                console.log(resp.data.error)
                if(resp.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                }else{
                    const result = resp.data.data;
                    if(result?.length){
                        for (let i in result) {
                            this.proxyDataset.push({
                                time_frame: ( this.filter.period === 'Hourly')? `${moment(result[i].date).format("hh:mm a")} - ${moment(result[i].date).add(1,'hours').format("hh:mm a")}`: '24 hr' ,
                                date: moment(result[i].date).format("L"),
                                provider:result[i].provider,
                                message:result[i].message,
                                count: result[i].count, 
                                attempts: result[i].attempts,
                                percentage:result[i].percentage + ' %',
                        })
                        }
                        // if(this.filter.time == "") { // check if the time is any empty string and dont not filter
                        //     for (const item in this.proxyDataset) this.report.push(this.proxyDataset[item]);
                        // }else{
                        //     for (const item in this.proxyDataset)
                        //
                        //         if(this.proxyDataset[item].time_frame == this.filter.time)// filter is date match
                        //             this.report.push(this.proxyDataset[item]);
                        //
                        //
                        // }

                       //checks for duplicates, count then  remove them
                       //  this.reports  = [...this.report.reduce( (mp, o) => {
                       //      const key = JSON.stringify([o.time,o.message, o.provider]);// what to check for
                       //      if (!mp.has(key)) mp.set(key, { ...o, Count: 0});
                       //          mp.get(key).Count++;// number of duplicates
                       //      return mp;
                       //  }, new Map).values()];

                        this.reports = this.proxyDataset;
                        this.showExportButton = true;
                        this.reportLoading = false;
                    }
                    else{
                        swal ({title:'Oops', text: `No records found`, icon: 'info'});
                        this.reportLoading = false;
                        this.reports =[];
                    }
                }
                this.searching = false;
            }).catch((err) => {
                this.reportLoading = false;
                this.searching = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            }).finally(() => {
                this.reportLoading = false;
                this.searching = false;
            });
        },
        stage: function (dataset) {

            // console.log(dataset.date)
            const geoHash = this.hashGeoTracking(
                // dataset.data.country,
                // dataset.data.state_name,
                // dataset.data.city,
                // dataset.data.ip
            );
            const errorHash = this.hashErrorTracking(geoHash,dataset.errorCode);
            if (!this.proxyDataset.hasOwnProperty(errorHash)) {
                this.proxyDataset[errorHash] = {

                    time_frame: this.getTimeFrame(dataset.date),
                    date: this.getDate(dataset.date),
                    provider:dataset.proxy.provider,
                    message:dataset.message

                };
            }
            this.proxyDataset[errorHash].error_count++;
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign} ${this.filter.client}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.reports,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        getTimeFrame: function(dateTime){
            const timeFrameMap = [
                '12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'
            ];
            let d = new Date(dateTime);
            return timeFrameMap[d.getHours()];
        },
        hashGeoTracking: function(country,state,city,ip){
            return sha1(`${country}${state}${city},${ip}`);
        },
        hashErrorTracking: function(geoHash,errorCode){
            return sha1(`${geoHash}${errorCode}`);
        }
    }
}
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>


